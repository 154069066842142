<!--布局页面-->
<template>
  <div>
    <!-- <v-head /> -->
    <div class="head">
      <el-row :gutter="0">
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>      
        <el-col :sm="24" :md="24" :lg="22" :xl="16" >
          <div class="flex-between">
            <div class="logo">
              <router-link to="/">
                <img src="@/assets/images/logo.png" />
              </router-link>
              <!-- <span>五里多农博广场</span> -->
              <img src="@/assets/images/logo1.png" style="margin-left: 50px;" />
              <span style="color: #1D2088">云南建投建筑机械有限公司</span>
            </div>
            <div class="logo-1">
              联系人:蒋红水，联系电话:18314451898
            </div>
          </div>
        </el-col>
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
      </el-row>
      <el-row :gutter="0" style="background-color:#007932;">
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>      
        <el-col :sm="24" :md="24" :lg="22" :xl="16" >          
          <div class="menu">
            <el-menu :default-active="$route.path" mode="horizontal" router :ellipsis="true" 
              background-color="#007932" text-color="#fff" active-text-color="#ffd04b" style="width: 100%"> 
              <template v-for="(menu,index) in menuData" :key="index" >
                <el-sub-menu v-if="menu.menuItem.length > 0" :index="menu.id">
                  <template #title>
                    <span>{{menu.name}}</span>
                  </template>
                  <div v-for="item1 in menu.menuItem" :key="item1.index">
                    <el-sub-menu v-if="item1.menuItem.length > 0" :index="item1.id">
                      <template #title>{{item1.name}}</template>
                      <div v-for="item2 in item1.menuItem" :key="item2.index">
                        <el-sub-menu v-if="item2.menuItem.length > 0" :index="item2.id">
                          <template #title>{{item2.name}}</template>
                          <el-menu-item :index="item3.path" v-for="item3 in item2.menuItem" :key="item3.index">{{item3.name}}</el-menu-item>
                        </el-sub-menu>
                        <el-menu-item v-else :index="item2.path">{{item2.name}}</el-menu-item>
                      </div>
                    </el-sub-menu>
                    <el-menu-item v-else :index="item1.path">{{item1.name}}</el-menu-item>
                  </div>
                </el-sub-menu>
                <el-menu-item v-else :index="menu.path">
                  <template v-if="menu.path.startsWith('https://')" #title><a :href="menu.path" target="_blank" >{{menu.name}}</a></template>
                  <template v-else #title>{{menu.name}}</template>                  
                </el-menu-item>
              </template>
            </el-menu>
          </div>
        </el-col>
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
      </el-row>
    </div>
    <el-row :gutter="0" :class="[$route.path == '/index' ? 'home_bgcolor' : 'page_bgcolor']">
      <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
        <el-col :sm="24" :md="24" :lg="22" :xl="16">
          <router-view />
        </el-col>
      <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
    </el-row>
    <div :class="[$route.path == '/index' ? 'foot' : 'foot1']"> 
      <el-row :gutter="0">
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
        <el-col :sm="24" :md="24" :lg="22" :xl="16">
          <div class="foot-div">
            <div>
              Copyright © {{year}} 云南建投建筑机械有限公司
              <!-- All Rights Reserved ©  <br />
              版权所有 ©  -->
            </div>            
            <!-- <div>  
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902003472" target="_blank">沪公网安备 31010902003472号 &nbsp;沪ICP备2023006978号-1</a>
            </div>-->
          </div>
        </el-col>
        <el-col :sm="0" :md="0" :lg="1" :xl="4"></el-col>
      </el-row>
    </div>
  </div>  
</template>

<script>
// import Head from '@/components/vhead'
// import Foot from '@/components/vfoot'
export default {
  // name: 'home',
  // components: {
  //   'v-head': Head,
  //   'v-foot': Foot
  // },
  data () {
    return {
      year: new Date().getFullYear(),
      menuData: [
        {
          id: '100',
          name: '首页',
          path: '/index',
          // icon: 'HomeFilled',
          menuItem:[]
        },
        {
          id: '200',
          name: '关于我们',
          path: '/about',
          menuItem:[]
        },
        {
          id: '300',
          name: '收支交易明细',
          path: '/dealdetail',
          menuItem:[]
        },
        {
          id: '400',
          name: '市场采购报表',
          menuItem: [
          {
            id: '401',
            name: '市场采购汇总',
            path: '/purchasesummary',
            menuItem:[]
          },
          {
            id: '402',
            name: '市场采购明细',
            path: '/purchasedetail',
            menuItem:[]
          }]
        },
        { 
          id: '500',         
          name: '商户供货报表',
          menuItem: [{
            id: '501',
            name: '商户供货汇总',
            path: '/sellsummary',
            menuItem:[]
          },
          {
            id: '502',
            name: '商户供货明细',
            path: '/selldetail',
            menuItem:[]
          }]
        },
        {  
          id: '600',        
          name: '商户收款报表',
          menuItem: [{
            id: '601',
            name: '商户收款汇总',
            path: '/paysummary',
            menuItem:[]
          },
          {
            id: '602',
            name: '商户收款明细',
            path: '/paydetail',
            menuItem:[]
          }]
        },
        {     
          id: '700',     
          name: '交易日报',
          menuItem: [{
            id: '701',
            name: '交易日报汇总',
            path: '/daysummary',
            menuItem:[]
          },
          {
            id: '702',
            name: '交易日报明细',
            path: '/daydetail',
            menuItem:[]
          }]
        },
        {  
          id: '800',        
          name: '交易月报',
          menuItem: [{
            id: '801',
            name: '交易月报汇总',
            path: '/monthsummary',
            menuItem:[]
          },
          {
            id: '802',
            name: '交易月报明细',
            path: '/monthdetail',
            menuItem:[]
          }]
        },
        {
          id: '900',
          name: '进入管理系统',
          path: 'https://ob.huoyiduo.cn/apps/ThoughtPower-scm-WldSupplyOnlineApp/#/LoginModule/LoginModule-LoginModule_index',
          menuItem:[]
        }
      ] 
    }
  },
  mounted () {    
  },
  methods: {
    // menuClick(item) {
    //   if (item.path.startsWith('https://')) {
    //     //页面重定向
    //     // window.location.href = item.path
    //     window.open(item.path, '_blank');
    //   } else {
    //     this.$router.push({ path: item.path })
    //   }
    // }
  },
  watch: {
    // $route(to, from) {
    //   console.log('to, from', to, from)
    //   this.activeMenu = to.path; // 监听路由变化，保持activeMenu最新
    // }
  }
}
</script>

<style>
.head{
  /* padding: 5px 0; */
  color: #333;  
}
/* .head1 .el-col {
  padding: 0;  
} */
.logo {
  display: flex; 
  flex-wrap: nowrap; 
	align-items:center;
  padding: 10px 0;
}
.logo img{
  width: auto;
  height: 100px;
  /* max-width: 280px;
  height: auto; */
}
.logo span{
  margin: 0 15px;
  font-size: 28px;
  font-weight: 800;
  color: #444;
  /* color: #007932; */
  letter-spacing: 2px;
}
.logo-1 {
  color: #999;
}
.menu {
  display: flex;
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: center;  
}
.el-menu-item {  
  padding: 0 29px;
}
.el-menu-item, .el-sub-menu span {
  font-size: 16px;
  font-weight: 600;
  line-height: 50px;
}
.el-menu--horizontal {
  border-bottom: none;
}
.el-sub-menu .el-menu-item {
  background-color: #999;
}

.foot {
  padding: 30px 0;
  /* background-color:#79bbff; */
  background-color: #F2F3F7;
  color: #333;
  font-size: 13px;
  text-align: center;
}
.foot1 {
  padding: 30px 0;
  /* background-color:#79bbff; */
  background-color: #fff;
  color: #333;
  font-size: 13px;
  text-align: center;
}
.foot-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.foot-div img {
  width: 120px;
  height: auto;
}.foot-div div {
  line-height: 200%;
}
</style>
